import { createApp } from 'vue';

import i18n from './lang';
import mitt from 'mitt';
import MainService from './services/MainService';


/* Swal */
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
const swalOptions = {
    confirmButtonColor: '#2A54CD',
    cancelButtonColor: '#2A54CD',
    padding: '24px',
    color: '#1A1C1E'
};

/* Axios */
import VueAxios from 'vue-axios';
import axiosInstance from "./api/axios.js";



/*Components*/
import iButton from './components/partial/elements/ButtonEl';
import iInput from './components/partial/elements/InputEl';
import iSelect from './components/partial/elements/SelectEl';
import iDropdown from './components/partial/elements/DropdownEl';
import iLoading from './components/partial/elements/LoadingEl';
import iTextarea from './components/partial/elements/TextareaEl';
import TreeviewItemDown from './components/treeview/TreeviewItemDown';
import TreeviewItemUp from './components/treeview/TreeviewItemUp';
import { ElTable, ElTableColumn, ElPagination } from 'element-plus';



import router from './routes';

import {createStore} from 'vuex';
import user from "./store/modules/user";
import settings from "./store/modules/settings";
import dropdown from "./store/modules/dropdowns";
import modals from "./store/modules/modals";
window.store = createStore({
    modules: {user, settings, dropdown, modals}
});
const emitter = mitt();

import App from './App.vue';
const app = i18n(createApp(App).use(router).use(window.store));

app.mixin(MainService);
app.use(VueAxios, axiosInstance);
app.use(VueSweetalert2, swalOptions);
app.config.globalProperties.emitter = emitter;
app.component('iButton', iButton);
app.component('iInput', iInput);
app.component('iSelect', iSelect);
app.component('iDropdown', iDropdown);
app.component('iLoading', iLoading);
app.component('iTextarea', iTextarea);
app.component('ElTable', ElTable);
app.component('ElTableColumn', ElTableColumn);
app.component('ElPagination', ElPagination);
app.component('TreeviewItemDown', TreeviewItemDown);
app.component('TreeviewItemUp', TreeviewItemUp);
app.mount('#app');
