import {createRouter, createWebHistory} from 'vue-router'

// Define route components.
import home from '@/components/map/IndexPage';
import auth from '@/components/auth/AuthIndex';
import reports from '@/components/reports/IndexPage';
import activities from '@/components/activites/IndexPage';
import error from '@/components/errors/404Error';


// Define routes
const router = createRouter({
    // mode: 'history',
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'home',
            component: home,
            meta: {
                title: "AIS - Grid Mapping",
                requiresAuth: true
            }
        },


        {
            path: '/login',
            name: 'login',
            component: auth,
            meta: {
                title: "AIS - Sign in",
            }
        },
        {
            path: '/recover',
            name: 'recover',
            component: auth,
            meta: {
                title: "AIS - Recover Password",
            }
        },


        {
            path: '/reports',
            name: 'reports',
            component: reports,
            meta: {
                title: "AIS - Reports",
                requiresAuth: true
            }
        },

        {
            path: '/activities',
            name: 'activities',
            component: activities,
            meta: {
                title: "AIS - Activities",
                requiresAuth: true
            }
        },


        {
            path: '/:pathMatch(.*)*',
            component: error,
            meta: {
                title: "AIS - Page Error",
                requiresAuth: true
            }
        },
    ],
    scrollBehavior() {
        return { top: 0 }
    },
});

router.beforeEach((to, from, next) => {
    /*redirect to account from login or register */
    if ((to.name === 'login' || to.name === 'recover') && localStorage.getItem('token')) {
        window.location.pathname = "/";
    }

    /*check login*/
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!localStorage.getItem('token')) {
            window.location.pathname = "/login";
        }
        if (localStorage.getItem('token') && (!localStorage.getItem('city')|| !localStorage.getItem('country'))) {
            localStorage.removeItem('token');
            window.location.pathname = "/login";
        }
        else {
            next();
        }
    } else {
        next();
    }

    if (to.meta.title) document.title = to.meta.title;
    return next()
});

export default router