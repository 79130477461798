<template>
    <header>
        <div class="container">
            <div class="navigation--top">
                <div class="d-flex align-center">
                    <router-link class="me-12 d-block" to="/"><img src="/img/logo.svg" alt="" width="48" height="48"></router-link>

                    <i-dropdown title="AIS" :options="dropdown" class="me-4"></i-dropdown>

                    <nav>
                        <router-link to="/">{{$t('nav.1')}}</router-link>
                        <router-link to="/reports">{{$t('nav.2')}}</router-link>
                        <router-link to="/activities">{{$t('nav.3')}}</router-link>
                    </nav>
                </div>

                <div class="d-flex align-center">
                    <i-button @click="logOut()" extrasmall title="Log out" class="me-4"></i-button>
                    <div class="me-11 notifications"><i-button icon img="/svg/notification.svg"></i-button></div>
                    <div><img src="/img/avatar.png" alt=""></div>
                </div>

            </div>
        </div>
    </header>
</template>

<script>
    export default {
        data() {
            return {
                dropdown: [
                    {'href': '/', title: 'Item 1'},
                    {'href': '/', title: 'Item 2'},
                    {'href': '/', title: 'Item 3'}
                ],
            }
        },

        methods: {
            logOut() {
                localStorage.removeItem('token');
                localStorage.removeItem('areaid');
                localStorage.removeItem('city');
                localStorage.removeItem('country');
                window.location.pathname = "/login";
            }
        }
    }
</script>

<style scoped lang="sass">
    header
        background: var(--background-white)
        box-shadow: var(--shadow-100)

        .navigation--top
            min-height: 72px
            display: flex
            align-items: center
            justify-content: space-between

        nav
            display: flex
            a
                display: inline-block
                padding: 12px 16px
                font-size: 16px

            a.router-link-active
                color: var(--color-primary)
                text-decoration: underline

        svg
            width: 24px
            path
                fill: red



</style>