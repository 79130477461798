<template>
    <div>
        <div class="select-element" :class="{'select-disabled': disabled, 'active': show, 'selected': valueTitle, 'select-small': small, 'select-dark': dark}" ref="select">
            <div class="label" v-if="label" @click="showMenu()">{{label}}</div>
            <div class="select-value-title" @click="showMenu()">

                <span v-if="rangeDateSelect">{{rangeDateSelect.start}} - {{rangeDateSelect.end}}</span>
                <span v-if="!rangeDateSelect && valueTitle && !multiple">{{valueTitle}}</span>
                <div v-if="!rangeDateSelect && valueTitle && valueTitle.length && multiple" class="selected-multiple">
                    <span v-if="valueTitle.length > 0" class="selected-multiple-item" @click.prevent="cancelItem(item)">{{valueTitle[0]}}</span>
                    <span v-if="valueTitle.length > 1" class="selected-multiple-item" @click.prevent="cancelItem(item)">{{valueTitle[1]}}</span>
                    <span v-if="valueTitle.length > 2" class="selected-multiple-item additional">+{{valueTitle.length - 2}}</span>
                </div>

                <span v-if="!rangeDateSelect && !valueTitle && placeholder && !multiple" class="placeholder">{{placeholder}}</span>
                <span v-if="!rangeDateSelect && multiple && placeholder" class="placeholder" :class="{'active': valueTitle.length}">{{placeholder}}</span>
            </div>
            <div class="select-menu" :class="{'fromBottom': fromBottom}" v-if="show">
                <div class="select-menu-header" v-if="multiple">
                    <div @click="selectAll()" class="select">{{$t('buttons.13')}}</div>
                    <div @click="resetAll()" class="reset">{{$t('buttons.14')}}</div>
                </div>
                <div class="select-menu-item"
                     :class="{'active': selectedValue(option[option_key]), 'select-menu-item-multiple': multiple}"
                     @click="select(option)"
                     v-for="option in options"
                     :key="option[option_key]">
                    {{option[title]}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            modelValue: {},
            options: Array,
            title: {
                type: String,
                default: 'value'
            },
            option_key: {
                type: String,
                default: 'value'
            },
            id: {
                default: 1
            },
            deselect: {
                type: Boolean,
                default: false
            },
            label: {
                type: String
            },
            placeholder: {
                default: null
            },
            disabled: {
                type: Boolean,
                default: false
            },
            multiple: {
                type: Boolean,
                default: false
            },
            rangeDateSelect: {
                type: Object, Array,
                default: null
            },
            small: {
                type: Boolean,
                default: false
            },
            dark: {
                type: Boolean,
                default: false
            }

        },
        emits: ['update:modelValue', 'change'],
        data() {
            return {
                show: false,

                fromBottom: false
            }
        },
        computed: {
            value: {
                get() {
                    return this.modelValue
                },
                set(value) {
                    this.$emit('update:modelValue', value)
                }
            },
            valueTitle() {
                let result;
                if(!this.multiple) {
                    this.options.forEach(el => {
                        if (el[this.option_key] == this.value) result = el[this.title];
                    });
                }
                if(this.multiple) {
                    result = [];
                    this.options.forEach(el => {
                        if(this.value && this.value.includes(el[this.option_key])) result.push(el[this.title]);
                    });
                }
                return result;
            }
        },

        created() {
            document.addEventListener('click', this.closeSelect)
        },

        methods: {
            showMenu() {
                let clientHeight = this.$refs.select.offsetParent.clientHeight;
                let offsetTop = this.$refs.select.offsetTop;
                if(!this.disabled) {
                    if(clientHeight - offsetTop - 240 < 0) this.fromBottom = true;
                    this.show = !this.show;

                }
            },
            select(e) {
                if(!this.multiple){
                    this.show = !this.show;
                    if (this.value == e[this.option_key] && !this.deselect) {
                        this.value = null;
                        this.$emit('change', e);
                        return;
                    }
                    this.value = e[this.option_key]
                }
                if(this.multiple) {
                    if(this.value && this.value.includes(e[this.option_key])) {
                        let index = this.value.findIndex((x) => x == e[this.option_key]);
                        this.value.splice(index, 1);
                        return;
                    }
                    this.value.push(e[this.option_key])
                }
                this.$emit('change', e);
            },
            closeSelect(e) {
                let el = this.$refs.select;
                let target = e.target;
                if (el && el !== target && !el.contains(target)) {
                    this.show = false
                }
            },

            cancelItem(e) {
                let index = this.value.findIndex((x) => x == e);
                this.value.splice(index, 1);
            },

            selectedValue(option) {
                let result = false;
                if(!this.multiple && this.value == option) result = true;
                if(this.multiple && this.value && this.value.includes(option)) result = true;
                return result;
            },

            selectAll() {
                this.options.forEach(option => {
                    if(!this.value.includes(option[this.option_key])) this.value.push(option[this.option_key])
                });
                this.show = false;
            },

            resetAll() {
                this.value = [];
                this.show = false;
            }
        }
    }
</script>